@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");

h1,
h2,
h3,
h4 {
  font-weight: 100;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h3,
h4 {
  margin-top: 5px;
  font-weight: 600;
}
.nav > li > a {
  color: #a7b1c2;
  font-weight: 600;
  padding: 14px 20px 14px 25px;
}
.nav.navbar-right > li > a {
  color: #999c9e;
}
.nav > li.active > a {
  color: #ffffff;
}
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background-color: #293846;
  color: white;
}
.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  background-color: transparent;
}
.nav > li > a i {
  margin-right: 6px;
}
.navbar {
  border: 0;
  padding: 10px 15px;
}
.navbar::before,
.navbar::after,
.navbar > *::before,
.navbar > *::after {
  all: initial;
}
.navbar-default {
  background-color: transparent;
  border-color: #2f4050;
}
.navbar-top-links li {
  display: inline-block;
}
.navbar-top-links li a {
  padding: 20px 10px;
  min-height: 50px;
}
.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
}
.dropdown-menu > li {
  width: 100%;
}
.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1ab394;
  outline: 0;
}
.nav-header {
  padding: 33px 25px;
  background-color: #2f4050;
  background-image: url("header-profile.png");
}
.pace-done .nav-header {
  transition: all 0.4s;
}
ul.nav-second-level {
  background: #293846;
}
.nav > li.active {
  border-left: 4px solid #19aa8d;
  background: #293846;
}
.nav.nav-second-level > li.active {
  border: none;
}
.nav-header a {
  color: #DFE4ED;
}
.nav-header .text-muted {
  color: #8095a8;
}
.minimalize-styl-2 {
  padding: 4px 12px;
  font-size: 14px;
  float: left;
}
.nav.navbar-top-links a {
  font-size: 14px;
}
.nav-second-level li {
  border-bottom: none !important;
}
.nav-second-level li a {
  padding: 7px 10px 7px 10px;
  padding-left: 52px;
}
.nav-second-level li:last-child {
  margin-bottom: 10px;
}
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 140px;
  height: auto;
}
body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}
.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}
.mini-navbar .nav .nav-second-level {
  position: absolute;
  left: 70px;
  top: 0;
  background-color: #2f4050;
  padding: 10px 10px 10px 10px;
  font-size: 12px;
}
.mini-navbar li.active .nav-second-level {
  left: 65px;
}
.logo-element {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  display: none;
  padding: 18px 0;
}
.pace-done .navbar-static-side,
.pace-done .nav-header,
.pace-done li.active,
.pace-done #page-wrapper,
.pace-done .footer {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar-static-top {
  background: #f3f3f4;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none;
  border-color: #dddddd #dddddd rgba(0, 0, 0, 0);
  border-bottom: #f3f3f4;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: #555555;
  cursor: default;
}
.nav.nav-tabs li {
  background: none;
  border: none;
}
body.mini-navbar .navbar-static-side {
  overflow: unset;
  position: absolute;
  width: 70px;
  z-index: 10;
}
body.mini-navbar .profile-element,
body.mini-navbar .nav-label,
body.mini-navbar .navbar-default .nav li a span {
  display: none;
}
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
  display: none;
}
body.mini-navbar .navbar-default .nav > li > a {
  font-size: 16px;
}
body.mini-navbar .logo-element {
  display: block;
}
body.mini-navbar .nav-header {
  padding: 0;
  background-color: #1ab394;
}
body.mini-navbar #page-wrapper {
  margin: 0 0 0 70px;
}
.btn {
  border-radius: 3px;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.btn-primary {
  background-color: #1ab394;
  border-color: #1ab394;
  color: #FFFFFF;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:active:hover {
  background-color: #18a689;
  border-color: #18a689;
  color: #FFFFFF;
}
.btn-primary:active {
  background-image: none;
}
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active {
  background-color: #1dc5a3;
  border-color: #1dc5a3;
}
.btn-success {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: #FFFFFF;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success:active:focus,
.btn-success:active:hover {
  background-color: #1a7bb9;
  border-color: #1a7bb9;
  color: #FFFFFF;
}
.btn-success:active {
  background-image: none;
}
.btn-default {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus,
.btn-default:active:hover {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-default:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-danger {
  background-color: #ed5565;
  border-color: #ed5565;
  color: #FFFFFF;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger:active:focus,
.btn-danger:active:hover {
  background-color: #ec4758;
  border-color: #ec4758;
  color: #FFFFFF;
}
.btn-danger:active {
  background-image: none;
}
.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white:active:focus,
.btn-white:active:hover {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-white:active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-white:active {
  background-image: none;
}
.form-control,
.form-control:focus,
.panel,
.progress,
.progress-bar {
  box-shadow: none;
}
button.dim, a.dim {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}
button.dim:active, a.dim:active {
  top: 3px;
}
button.btn-primary.dim, a.btn-primary.dim {
  box-shadow: inset 0 0 0 #16987e, 0 5px 0 0 #16987e, 0 10px 5px #999999;
}
button.btn-primary.dim:active, a.btn-primary.dim:active {
  box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999999;
}
button.btn-default.dim, a.btn-default.dim {
  box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999999;
}
button.btn-default.dim:active, a.btn-default.dim:active {
  box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999999;
}
button.btn-success.dim, a.btn-success.dim {
  box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999999;
}
button.btn-success.dim:active, a.btn-success.dim:active {
  box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999999;
}
button.btn-danger.dim, a.btn-danger.dim {
  box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999999;
}
button.btn-danger.dim:active, a.btn-danger.dim:active {
  box-shadow: inset 0 0 0 #ea394c, 0 2px 0 0 #ea394c, 0 5px 3px #999999;
}
button.dim:before, a.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;
}
button.dim:active:before, a.dim:active:before {
  top: 7px;
  font-size: 50px;
}
.btn:focus {
  outline: none !important;
}
.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
}

.ibox .label {
  font-size: 10px;
}
.label-primary {
  background-color: #1ab394;
  color: #FFFFFF;
}
.label-success {
  background-color: #1c84c6;
  color: #FFFFFF;
}
.label-warning {
  background-color: #f8ac59;
  color: #FFFFFF;
}
.label-danger {
  background-color: #ed5565;
  color: #FFFFFF;
}
.label-info {
  background-color: #23c6c8;
  color: #FFFFFF;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus {
  background-color: #f4f4f4;
  border-color: #DDDDDD;
  color: inherit;
  cursor: default;
  z-index: 2;
}
.pagination > li > a {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  color: inherit;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 4px 10px;
  position: relative;
  text-decoration: none;
}
.media-body {
  overflow: hidden;
}
.list-group-item {
  background-color: inherit;
  border: 1px solid #e7eaec;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}
.elements-list .list-group-item {
  border-left: none;
  border-right: none;
  padding: 15px 25px;
}
/* .elements-list a {
  color: inherit;
} */
.elements-list .list-group-item:hover {
  background: #f3f3f4;
  color: inherit;
  border-color: #e7eaec;
  border-radius: 0;
}
.element-detail-box {
  padding: 0 25px;
}
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #1ab394;
  position: fixed;
  z-index: 2040;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}
.pace-inactive {
  display: none;
}
.form-control {
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}
.form-control:focus {
  border-color: #1ab394;
}
.input-group-addon {
  background-color: #fff;
  border: 1px solid #E5E6E7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  text-align: center;
}
#toast-container > :hover {
  -moz-box-shadow: 0 0 4px #999;
  -webkit-box-shadow: 0 0 4px #999;
  box-shadow: 0 0 4px #999;
  opacity: 1;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
.tabs-container .panel-body {
  background: #fff;
  border: 1px solid #e7eaec;
  border-radius: 2px;
  padding: 20px;
  position: relative;
}
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
  background-color: #fff;
}
.tabs-container .nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.tabs-container .tab-pane .panel-body {
  border-top: none;
}
.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover,
.tabs-container .nav-tabs > li.active > a:focus {
  border: 1px solid #e7eaec;
  border-bottom-color: transparent;
}
.tabs-container .nav-tabs {
  border-bottom: 1px solid #e7eaec;
}
.tabs-container .tab-pane .panel-body {
  border-top: none;
}
.tabs-container .nav-tabs > li a:hover {
  background: transparent;
  border-color: transparent;
}
.tabs-container .tab-content > .tab-pane {
  display: none;
}
.tabs-container .tab-content > .active {
  display: block;
}
@media (max-width: 767px) {
  .tabs-container .nav-tabs > li {
    float: none !important;
  }
  .tabs-container .nav-tabs > li.active > a {
    border-bottom: 1px solid #e7eaec !important;
    margin: 0;
  }
}
.stream {
  position: relative;
  padding: 10px 0;
}
.stream:first-child .stream-badge:before {
  top: 10px;
}
.stream:last-child .stream-badge:before {
  height: 30px;
}
.stream .stream-badge {
  width: 50px;
}
.stream .stream-badge i {
  border: 1px solid #e7eaec;
  border-radius: 50%;
  padding: 6px;
  color: #808486;
  position: absolute;
  background-color: #ffffff;
  left: 8px;
}
.stream .stream-badge i.bg-primary {
  color: #ffffff;
  background-color: #1ab394;
  border-color: #1ab394;
}
.stream .stream-badge i.bg-warning {
  color: #ffffff;
  background-color: #f8ac59;
  border-color: #f8ac59;
}
.stream .stream-badge:before {
  content: '';
  width: 1px;
  background-color: #e7eaec;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
}
.stream .stream-info {
  font-size: 12px;
  margin-bottom: 5px;
}
.stream .stream-info .date {
  color: #9a9d9f;
  font-size: 80%;
}
.stream .stream-panel {
  margin-left: 55px;
}
.media-body {
  font-size: 12px;
}
body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #2f4050;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;
}
html,
body {
  height: 100%;
}
#page-wrapper {
  min-height: auto;
}
.block {
  display: block;
}
.clear {
  display: block;
  overflow: hidden;
}
a {
  cursor: pointer;
}
a:hover,
a:focus {
  text-decoration: none;
}
.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}
.font-bold {
  font-weight: 600;
}
.hr-line-dashed {
  border-top: 1px dashed #e7eaec;
  color: #ffffff;
  background-color: #ffffff;
  height: 1px;
  margin: 20px 0;
}
#wrapper {
  width: 100%;
  overflow-x: hidden;
}
.wrapper {
  padding: 0 20px;
}
.wrapper-content {
  padding: 20px 10px 40px;
}
#page-wrapper {
  padding: 0 15px;
  min-height: 568px;
  position: relative !important;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 240px;
    min-height: 2002px;
  }
}
.ibox-content h2,
.ibox-content h3,
.ibox-title h2,
.ibox-title h3 {
  margin-top: 5px;
}
.footer {
  background: none repeat scroll 0 0 white;
  border-top: 1px solid #e7eaec;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  position: absolute;
  right: 0;
}
.table > thead > tr > th {
  border-bottom: 1px solid #DDDDDD;
  vertical-align: bottom;
}
.table > thead > tr > th,
.table > tbody > tr > td {
  border-top: 1px solid #e7eaec;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}
.nav-tabs > li > a {
  color: #A7B1C2;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: #e6e6e6;
  color: #676a6c;
}
.border-bottom {
  border-bottom: 1px solid #e7eaec;
}
.full-width {
  width: 100% !important;
}
.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}
.ibox:after,
.ibox:before {
  display: table;
}
.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 15px 7px;
  min-height: 48px;
}
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.ibox-content {
  clear: both;
}
.gray-bg {
  background-color: #f3f3f4;
}
.white-bg {
  background-color: #ffffff;
}
.bg-primary {
  background-color: #1ab394;
  color: #ffffff;
}
.bg-warning {
  background-color: #f8ac59;
  color: #ffffff;
}
.progress-bar {
  background-color: #1ab394;
}
.jumbotron {
  border-radius: 6px;
  padding: 40px;
}
.text-muted {
  color: #888888;
}
.full-height {
  height: 100%;
}
.fh-breadcrumb {
  height: calc(100% - 196px);
  margin: 0 -15px;
  position: relative;
}
.fh-column {
  background: #fff;
  height: 100%;
  /*width: 240px;*/
  float: left;
}
.m-t-xs {
  margin-top: 5px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.block {
  display: block !important;
}
.middle-box {
  max-width: 400px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
}
.loginscreen.middle-box {
  width: 300px;
}
.logo-name {
  color: #e6e6e6;
  font-size: 180px;
  font-weight: 800;
  letter-spacing: -10px;
  margin-bottom: 0;
}
.middle-box h1 {
  font-size: 170px;
}
.feed-element:first-child {
  margin-top: 0;
}
.feed-element {
  padding-bottom: 15px;
}
.feed-element {
  margin-top: 15px;
}
.feed-element,
.media-body {
  overflow: hidden;
}
.feed-element > .pull-left {
  margin-right: 10px;
}
.chat-avatar {
  width: 36px;
  height: 36px;
  float: left;
  margin-right: 10px;
}
.nav.metismenu {
  background-color: #2F4050;
}
.metismenu .collapse {
  display: none;
}
.metismenu .collapse.in {
  display: block;
}
.mini-navbar .metismenu .collapse {
  opacity: 0;
}
.mini-navbar .metismenu .collapse.in {
  opacity: 1;
}
.mini-navbar .metismenu .collapse a {
  display: none;
}
.mini-navbar .metismenu .collapse.in a {
  display: block;
}
.pace-inactive {
  display: none;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 220px;
    min-height: 100vh;
  }
  .navbar-static-side {
    height: 100vh;
    overflow: auto;
    position: fixed;
    width: 220px;
    z-index: 2001;
  }
}
@media (max-width: 768px) {
  #page-wrapper {
    position: inherit;
    margin: 0 0 0 0;
    min-height: 100vh;
  }
  .navbar-header {
    display: inline;
    float: left;
  }
  .navbar-static-side {
    display: none;
  }
}
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body {
    overflow: visible !important;
  }
  #page-wrapper {
    margin: 0;
  }
}

.xcomponent, .xcomponent > .xcomponent-outlet {
  width: 100% !important;
  height: 88vh !important;
}

.m-state {
  margin: 5px;
  padding: 5px;
  display: inline-block;
  padding: .5em .6em .5em;
  font-size: 84%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  text-align: center;
  width: 90%;
}

.m-state.m-approved {
  background-color: #2ECC71;
  color: #FFFFFF;
}

.m-state.m-pending {
  background-color: #ff9f43;
  color: #FFFFFF;
}

.m-state.m-created {
  background-color: #59ABE3;
  color: #FFFFFF;
}

.m-cancelled, .m-cancelled_by_client, .m-cancelled_by_merchant {
  background-color: #ff9f43;
  color: #FFFFFF;
}

.m-confirmed {
  background-color: #26A65B;
  color: #FFFFFF;
}

.m-created {
  background-color: #1c84c6;
  color: #FFFFFF;
}

.m-ready {
  background-color: #9B59B6;
  color: #FFFFFF;
}

.m-completed {
  background-color: #2ECC71;
  color: #fff;
}

.m-failed {
  background-color: #635dc2;
  color: #fff;
}
